<template>
    <div class="content">
        <h1>Search results</h1>
        <div class="search-result-count">Showing 100 of 32432 results</div>
        <p class="search-term">You searched:
            <span class="search-term-key">Yummy</span>
        </p>
        <el-row>
            <el-col :span="6" v-for="item in items" :key="item">
                <div class="card-wrap">
                    <el-card class="box-card" :body-style="{ padding: '0px' }">
                        <img class="image" src="http://placehold.it/300x300">
                        <div style="padding: 14px;">
                            <!-- <span>Yummy hamburger</span> -->
                            <div class="bottom clearfix" style="position: relative">
                                <time class="time">{{ currentDate }}</time>
                                <small>by Username</small>
                                <div style="float: right; margin-right: 12px; margin-top: 0px">
                                    <!-- <el-button type="text" class="button">View</el-button> -->
                                    <small><img src="../assets/chat.png" style="width: 24px; opacity: 0.4;vertical-align: middle;"> 123</small>
                                    <small><img src="../assets/cloud-computing.png" style="width: 24px; opacity: 0.4;vertical-align: middle;"> 123</small>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            items: ['one', 'two', 'three', 'four', 'five']
        }
    }
}
</script>
<style scoped>
.card-wrap {
    padding: 12px;
    padding-left: 0px;
}

.image {
    width: 100%;
    display: block;
}

.search-term {
    font-size: 130%;
    color: #333;
}

.search-term-key {
    color: purple;
    font-weight: bold;
}

.search-result-count {
    float: right;
    clear: none;
    margin-right: 12px;
    margin-top: 12px;
}
</style>
